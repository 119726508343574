<template>
  <v-col class="h-100 pt-10" align="center">
    <img src="/anybill_logo.svg" style="height: 50px; object-fit: contain" />
    <p class="text-h6 text-left mt-10">{{ $t("title") }}</p>
    <p class="pt-5 text-subtitle1 text-left">{{ $t("globalSubtitle") }}</p>
    <div class="pt-8 mx-auto">
      <v-combobox
        v-if="customersLoaded"
        v-model="selectedCustomer"
        :items="customers"
        :label="t('globalMerchantLabel')"
        variant="outlined"
        item-title="name"
        item-value="id"
        clearable
      ></v-combobox>
      <v-progress-circular v-else indeterminate> </v-progress-circular>
      <v-btn
        class="text-none w-100 mt-5"
        rounded
        color="black"
        variant="flat"
        :disabled="selectedCustomer === null"
        @click="submit"
      >
        {{ $t("globalSubmit") }}
      </v-btn>
    </div>
    <AnybillBranding class="branding" />
    <v-snackbar v-model="snackbar" color="red" timeout="4000">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-col>
</template>

<script setup lang="ts">
import { AnybillProvider } from "~/provider/anybillProvider";
import { useDisplay } from "vuetify";
import { useI18n } from "vue-i18n";
import AnybillBranding from "./general/anybill-branding.vue";
import ExternalCustomerDto from "~/models/customer/ExternalCustomer";

const { t, locale } = useI18n();

const { smAndDown } = useDisplay();

const search = ref("");

const selectedCustomer = ref<ExternalCustomerDto | null>(null);

const customers = ref<ExternalCustomerDto[]>([]);

const customersLoaded = ref(false);

const anybillProvider = AnybillProvider.Instance;

const snackbarMessage = ref<string | null>(null);

const snackbar = ref(false);

onMounted(async () => {
  await getCustomers();
});

async function getCustomers() {
  const getCustomersResponse = await anybillProvider.getCustomers();
  if (getCustomersResponse.success) {
    customers.value = getCustomersResponse.value;
  } else {
    snackbarMessage.value = t("customerFetchError");
    snackbar.value = true;
  }
  customersLoaded.value = true;
}

async function submit() {
  await navigateTo(`${locale.value}/${selectedCustomer.value?.id}`);
}
</script>

<style scoped lang="scss">
.branding {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
