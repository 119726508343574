<template>
  <main v-if="mounted">
    <CustomerSectionPage class="px-8" v-if="isMobileView" />

    <v-card
      v-else
      class="mx-auto mt-10 px-4 py-6 d-flex align-center justify-center"
      height="85vh"
      max-width="400px"
      rounded="lg"
    >
      <CustomerSectionPage />
    </v-card>
  </main>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { ref, computed, onMounted } from "vue";
import CustomerSectionPage from "~/components/CustomerSectionPage.vue";

const { smAndDown } = useDisplay();
const mounted = ref(false);

const isMobileView = computed(() => smAndDown.value);

onMounted(() => {
  mounted.value = true;
});
</script>
